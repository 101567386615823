:root {
  --primary-color: #009688;
  --primary-dark: #00796b;
  --primary-light: #b2dfdb;
  --accent: #03a9f4;
}
html,
#root {
  background-color: var(--primary-color);
}
body {
  display: flex;
  flex-direction: column;
}
/* Navigation */
.navbar {
  background-color: var(--primary-dark);
  display: flex;
}
.navbar-toggler {
  float: left;
}
.navbar-nav {
  float: none;
  margin: 0 auto;
  text-align: center;
}
.navbar-brand {
  position: absolute;
  width: 100%;
  z-index: -1;
  left: 0;
  top: 8px;
  text-align: center;
  margin: auto;
}
#notAuth li:nth-of-type(2) {
  padding-right: 125px;
  padding-left: 25px;
}
#notAuth li:nth-of-type(3) {
  padding-left: 125px;
  padding-right: 25px;
}
#auth li:nth-of-type(1) {
  padding-right: 125px;
}
#auth li:nth-of-type(2) {
  padding-left: 125px;
}
#logout {
  position: absolute;
  right: 50px;
}
@media (max-width: 576px) {
  #notAuth li:nth-of-type(2) {
    padding: 0;
  }
  #notAuth li:nth-of-type(3) {
    padding: 0;
  }
}
@media (max-width: 768px) {
  #auth li:nth-of-type(1) {
    padding-right: 0;
  }
  #auth li:nth-of-type(2) {
    padding-left: 0;
  }
  #logout {
    position: relative;
    right: 0;
  }
}
/* End Navigation */

/* Search Page */
#searchContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#search {
  max-width: 500px;
  width: 100%;
}
#search input {
  margin: 100px auto;
  margin-bottom: 50px;
  border-radius: 25px;
  border-style: hidden;
  height: 50px;
  width: 100%;
  text-align: center;
}
#search input:focus {
  outline: none;
}
@media (min-width: 576px) {
  .pac-container {
    margin-left: 50px !important;
    width: 400px !important;
  }
}
#locationCards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: auto;
  justify-content: center;
}
#locationCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 300px;
  margin: 5px;
}
#locationTitle {
  height: 50px;
}
#locationImg {
  height: 250px;
  width: 250px;
  margin: 15px auto;
  object-fit: cover;
}
#locationBody {
  text-align: center;
  display: flex;
  flex-direction: column;
  margin: 0;
}
.locationButton {
  margin: 15px;
  border-color: var(--accent) !important;
  background-color: var(--accent) !important;
}
/* End Search Page */

/* Login/Register Page */
#formContainer {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin-top: 100px;
  border-radius: 5%;
  width: 100%;
  max-width: 500px;
  padding: 0 50px;
}
#formContainer h1 {
  text-align: center;
  padding-top: 50px;
  padding-bottom: 25px;
  margin: 0;
}
.alert {
  width: 100%;
  text-align: center;
  align-self: center;
}
#form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.form-group {
  width: 100%;
  margin: auto;
  margin-bottom: 1rem;
}
#formButton {
  border-color: var(--accent);
  background-color: var(--accent);
  margin: 50px;
}
/* End of Login/Register Page */

/* Location Page */
#locationContainer {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  width: 100%;
}
#locationPage {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 0;
}
#locationPage h1 {
  grid-column: 1 / 3;
  text-align: center;
  grid-row: 1 / 2;
  padding-bottom: 50px;
  margin: 0;
}
#carouselContainer {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  width: 500px;
}
.carousel-item img {
  height: 500px;
  width: 500px;
  object-fit: cover;
}
.info {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}
#empty {
  background-color: white;
  font-size: 26px;
  width: 80%;
  max-width: 1000px;
  margin: auto;
  margin-top: 100px;
}
@media (max-width: 900px) {
  #locationContainer {
    justify-content: center;
    width: 100%;
  }
  #locationPage {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  #carouselContainer {
    margin-bottom: 50px;
    padding: 0;
  }
  .info h2 {
    width: 100%;
    padding: 25px;
    margin: auto;
  }
}
@media (max-width: 576px) {
  #carouselContainer {
    width: 250px;
  }
  .carousel-item img {
    height: 250px;
    width: 250px;
  }
}
/* End Location Page */
